import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

function ChangePlan() {
  return (
    <Layout>
      <SEO title="Past works" />
      <Wrapper>
        <CloseBtn>
          <Link to="/">
            <img src="images/icons/cancel.png" alt="cancel"></img>
          </Link>
        </CloseBtn>
        <ContentWrapper>
          <Bg1>
            <ContentHeaderWrapper> Change Plan </ContentHeaderWrapper>
          </Bg1>
        </ContentWrapper>
        <Bg2>
          <ContentBodyWrapper>
            <Header>Overview</Header>
            <Body>
              Current change plan page is not suitable for T-Mobile's latest
              plan (unlimited data plan). So the page has redesigned based on
              survey and data as we collect from users. In this project, I led
              the UX design from the start to the end, and here are the results
              of my design points:
            </Body>
            <Body>
              - T-Mobile users are more interested in plan benefits than data
              usage (because T-Mobile offers unlimited data plan as default).
            </Body>
            <Body>
              - Make sure the page is mobile friendly as we have more users
              access from a mobile app (My T-mobile).
            </Body>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <DesignProcess>
              <Header>Design Process & My Role</Header>
              <Body>
                In this project, I led the UX design from the start to the end.
              </Body>
              <img
                src="images/past/designprocess2.png"
                alt="designprocess2"
              ></img>
            </DesignProcess>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>
              Define problem:
              <span> Understand users pain points from previous project.</span>
            </Header>
            <Body>
              According to the data provided by the program manager, about 73.3%
              of online users were not able to receive trade-in credits
              successfully in 2016. There were a lot of reasons including user
              returned the iPhone without turning off the find my iPhone
              feature. Users were also confused with how they receive their
              trade-in credits. While promotion credits immediately applied to
              cart, standard trade-in credits applied to their next bill. These
              pain points resulted in increased care calls and customer
              dissatisfaction
            </Body>
            <img src="images/past/defineProblem.png" alt="defineProblem"></img>
            <Header>
              Investigation:
              <span> Checking past research data</span>
            </Header>
            <Body>
              First, I looked at old research data about how the decision on the
              current page came out.
              <img src="images/past/current.png" alt="current"></img>
              <Body>
                I found that the page was designed for the old plan that has
                option based on data usage. Currently, the T-Mobile plan only
                has one plan, and it is an unlimited data plan, so the page is
                not used for its intended purpose. I needed to design for
                T-Mobile One plan which is the unlimited data plan.
              </Body>
            </Body>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>Survey</Header>
            <Body>
              So, our researchers conducted surveys through Crowdtap to see what
              kind of information users are looking for new plan. We found two
              interesting facts: 1. T-Mobile customers were less likely to be
              looking for data usage. (b / c T-Mobile offers good 4G LTE and
              unlimited plan). 2. Compared to other carriers, T-Mobile customers
              were more likely to be looking for information, especially about
              current plan details, info about current promos/discounts, and new
              services to add.
            </Body>
            <img src="images/past/survey.png" alt="survey"></img>
            <Header>
              Target user:
              <span> Simple choice plan user</span>
            </Header>
            <Body>
              The simple choice is the name of the plan that before launching
              the new plan from T-Mobile (T-Mobile One). These users keep their
              old plan because their plan is cheaper than the new plan. So
              T-Mobile is offering a lot of benefits to the new plan so that
              hopefully these users switch to the new plan. Recently, T-Mobile
              offers free Netflix service with their new plan.
            </Body>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>
              Design Approach 1:
              <span>
                {" "}
                Tell users whether their device is eligible for trade-in ASAP
              </span>
            </Header>
            <Body>
              Based on survey, T-Mobile customers were more interested to learn
              about their new plan benefits and discounts compare to customers
              from other carriers. So, to reinforce that, I have designed one
              plan comparison page that user can view their current plan and
              able to compare with available plans.
            </Body>
            <img
              src="images/past/designApproach4.png"
              alt="design approach 4"
            ></img>
            <Body>
              I thought it would be easier for mobile users to swipe in ways
              that make it easier to compare plans. But, there is no card-based
              component in the T-Mobile design component at present. So we
              decided to proceed with usability testing.
            </Body>
            <img src="images/past/wire.png" alt="Wireframe"></img>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>
              Design Approach 2:
              <span>
                {" "}
                How might we help users to compare multiple new plans with their
                current one?
              </span>
            </Header>
            <Body>
              About 70% of users use T-Mobile service through mobile app(than
              web), so I designed the comparison of the plan to make it easier
              on mobile.
            </Body>
            <img
              src="images/past/designApproach5.png"
              alt="design approach 5"
            ></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>
              Design Approach 3:{" "}
              <span> Make it looks consistent all channels</span>
            </Header>
            <Body>
              Currently, many pages on T-Mobile have different style. So our
              design team are working together to create global CSS and ensure
              that all channels use the same fonts and styles to keep consistent
              over T-Mobile.com.
            </Body>
            <img
              src="images/past/globalCSS.png"
              alt="Current and New Design"
            ></img>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>Testing & iteration</Header>
            <Body>
              Our team and I explored 2 prototypes (Stack vs. Swipe) with 20
              participants in usability testing. Participants prefer swipe
              version(60%).
            </Body>
            <Body>
              “Because stack design almost hides other plans, if I don’t
              remember to scroll down enough. The swipe design makes sure that I
              get to see every plan they have”
            </Body>

            <Body>
              With final design, I eliminated more contents on the current plan
              and gave more space on available plans so that user don't have to
              scroll more to compare. (Because T-Mobile offers only one plan
              today the compare plan feature became out of scope for this round)
            </Body>
            <Testing>
              <Stack>
                <img src="images/past/stack.gif" alt="Stack"></img>Stack
              </Stack>
              <Swipe>
                <img src="images/past/Swipe.gif" alt="Swipe"></img>Swipe
              </Swipe>
              <Arrow>
                <img src="images/past/arrow.png" alt="Arrow"></img>
              </Arrow>
              <Final>
                <img src="images/past/Final.gif" alt="Final Design"></img>Final
              </Final>
            </Testing>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>Design Card Component</Header>
            <Body>
              Lastly, I worked with design owner to apply card component to
              global CSS libarary.
            </Body>
            <img src="images/past/zeplin.png" alt="Zeplin"></img>
            <Header>Where is it now?</Header>
            <Body>
              The project is currently in development and will be launching in
              March 2018.
            </Body>
          </ContentBodyWrapper>
        </Bg2>

        <Bg3>
          <ContentMenuWrapper>
            <Link to="/tradein">
              <Menu>
                <span role="img" aria-label="previous">
                  ⬅
                </span>
                <span> Previous project</span>
              </Menu>
            </Link>
            <Link to="/rooy">
              <Menu>
                Next project{" "}
                <span role="img" aria-label="next">
                  ⮕
                </span>
              </Menu>
            </Link>
          </ContentMenuWrapper>
        </Bg3>
      </Wrapper>
    </Layout>
  )
}

export default ChangePlan

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div`
  background: linear-gradient(rgba(19, 0, 9, 0.7), rgba(255, 0, 124, 0.78)),
    url("/images/past/plan.png");
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: end;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 944px) {
    height: 400px;
  }
`
const ContentHeaderWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 100%;
  color: #fff;
  padding-bottom: 86px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ContentBodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: 100%;
    margin-bottom: 24px;
  }
`

const ContentMenuWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`

const Bg1 = styled.div`
  width: 100%;
  padding: 86px 200px;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg2 = styled.div`
  width: 100%;
  padding: 86px 200px;
  background-color: #fff;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg3 = styled.div`
  width: 100%;
  padding: 86px 200px;
  background-color: #fff;

  @media (max-width: 944px) {
    padding: 48px;
    height: 160px;
  }
`

const DesignProcess = styled.div``

const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
  /* or 31px */

  color: #f50075;
  margin-bottom: 24px;

  span {
    color: #000;
  }
`

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;
`

const Menu = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  color: #2d577d;

  :hover {
    opacity: 0.8;
  }
`

const Testing = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;

  > * {
    width: 25%;
  }
`
const Arrow = styled.div`
  width: 97px;
  box-shadow: none;
`
const Swipe = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000;

  img {
    box-shadow: 0 5px 22px 0 rgb(0 0 0 / 22%), 0 -6px 21px 0 rgb(0 0 0 / 0%);
  }
`

const Stack = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000;

  img {
    box-shadow: 0 5px 22px 0 rgb(0 0 0 / 22%), 0 -6px 21px 0 rgb(0 0 0 / 0%);
  }
`

const Final = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000;
  img {
    box-shadow: 0 5px 22px 0 rgb(0 0 0 / 22%), 0 -6px 21px 0 rgb(0 0 0 / 0%);
  }
`

const CloseBtn = styled.div`
  display: block;
  position: fixed;
  margin: 35px 30px;

  :hover {
    opacity: 0.4;
  }

  @media (max-width: 944px) {
    display: none;
  }

  img {
    width: 50px;
    height: 50px;
    opacity: 0.7;
  }
`
